import React from 'react';

import { ThemeProvider } from '@material-ui/core';

import anoragroupPrimaryTheme from './anoragroup/primaryTheme';

import variables from '../variables';

function getPrimaryTheme() {
  switch (variables.siteId) {
    case 'anoragroup': {
      return anoragroupPrimaryTheme;
    }
    default: {
      return null;
    }
  }
}

function PrimaryThemeProvider(props) {
  return <ThemeProvider theme={getPrimaryTheme()} {...props} />;
}

export default PrimaryThemeProvider;
