import { get, isNil } from 'lodash';

import { isSSR } from './windowUtils';

export const viewPage = () => {
  const dataLayer = isSSR() ? null : get(window, 'dataLayer', []);

  if (isNil(dataLayer)) {
    return;
  }

  dataLayer.push({ event: 'pageView' });
};
