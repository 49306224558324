import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { isEqual, isNil, pick } from 'lodash';

const SearchContext = createContext({
  searchParams: null,
  updateSearchParams: () => {},
  allPages: null,
});

export function SearchProvider({ children }) {
  const queryResults = useStaticQuery(allPagesQuery);

  const allPages = pick(
    queryResults,
    'allContentfulAdvancedPage',
    'allContentfulArticlePage',
    'allContentfulLandingPage',
  );

  useEffect(() => {
    if (!isEqual(typeof window, 'undefined')) {
      setSearchParams(JSON.parse(window.sessionStorage.getItem('SearchContext.searchParams')));
    }
  }, []);

  const [searchParams, setSearchParams] = useState({
    searchTerm: '',
    startDate: '',
    endDate: '',
    categories: [],
    searchType: '',
  });

  const contextValue = useMemo(() => {
    const updateSearchParams = nextSearchParams => {
      setSearchParams(nextSearchParams);
      window.sessionStorage.setItem('SearchContext.searchParams', JSON.stringify(nextSearchParams));
    };

    return {
      allPages,
      searchParams,
      updateSearchParams,
    };
  }, [searchParams, allPages]);

  return <SearchContext.Provider value={contextValue}>{children}</SearchContext.Provider>;
}

SearchProvider.propTypes = {
  children: PropTypes.node,
};

SearchProvider.defaultProps = {
  children: null,
};

export const allPagesQuery = graphql`
  query allContentfulPages {
    allContentfulAdvancedPage {
      nodes {
        contentful_id
        fullPath
        description
        title
        node_locale
        menuTitle
      }
    }
    allContentfulArticlePage {
      nodes {
        contentful_id
        fullPath
        description
        title
        node_locale
        menuTitle
        publishingDate
        categories {
          title
          searchPage {
            ... on ContentfulAdvancedPage {
              fullPath
            }
          }
          contentful_id
        }
      }
    }
    allContentfulLandingPage {
      nodes {
        contentful_id
        fullPath
        description
        title
        node_locale
        menuTitle
      }
    }
    allContentfulCategory {
      nodes {
        contentful_id
        title
        node_locale
      }
    }
  }
`;

export function useSearch() {
  const context = useContext(SearchContext);
  if (isNil(context)) {
    throw new Error('useSearch must be used within a SearchProvider');
  }
  return context;
}
