/* eslint-disable import/no-extraneous-dependencies */

import React from 'react';
import RootLayout from './RootLayout';
import PageWrapper from '../../src/components/layout/PageWrapper';

export const wrapRootElement = ({ element }) => {
  return <RootLayout>{element}</RootLayout>;
};

export const wrapPageElement = props => {
  return <PageWrapper {...props} />;
};
