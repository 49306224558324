import React from 'react';
import PropTypes from 'prop-types';

import { I18nextProvider } from 'react-i18next';

import i18n from '../../translations';

export function TranslationProvider({ children }) {
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
}

TranslationProvider.propTypes = {
  children: PropTypes.node,
};

TranslationProvider.defaultProps = {
  children: null,
};
