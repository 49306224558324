import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './en/translation.json';
import fi from './fi/translation.json';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    fi: { translation: fi },
  },
  fallbackLng: 'en',
  supportedLngs: ['en', 'fi'],
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
