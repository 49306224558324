import React from 'react';
import { createTheme } from '@material-ui/core/styles';
import { CheckBoxOutlined } from '@material-ui/icons';

export const colors = {
  black: 'rgb(0, 0, 0)',
  white: 'rgb(255, 255, 255)',
  white50: 'rgba(255, 255, 255, 0.5)',
  whiteTransparent: 'rgba(255, 255, 255, 0.9)',
  gray: 'rgb(204, 204, 204)',
  lightGray: 'rgb(239, 239, 239)',
  dividerGray: 'rgb(222, 222, 222)',
  darkGray: 'rgb(51, 51, 51)',
  blue: 'rgb(70, 152, 202)',
  lightBlue: 'rgb(164, 200, 225)',
  lightBlueTransparent: 'rgba(164, 200, 225, 0.9)',
  darkBlue: 'rgb(27, 29, 54)',
  darkBlueTransparent: 'rgba(27, 29, 54, 0.9)',
  green: 'rgb(107, 187, 174)',
  lightGreen: 'rgb(189, 233, 201)',
  lightGreenTransparent: 'rgba(189, 233, 201, 0.9)',
  yellow: 'rgb(222, 184, 59)',
  lightYellow: 'rgb(233, 225, 134)',
  lightYellowTransparent: 'rgba(233, 225, 134, 0.9)',
  purple: 'rgb(184, 132, 203)',
  lightPurple: 'rgb(204, 174, 208)',
  lightPurpleTransparent: 'rgba(204, 174, 208, 0.9)',
};

const theme = createTheme({});

const primaryTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 840,
      lg: 1280,
      xl: 1920,
    },
  },
  mixins: {
    toolbar: {
      minHeight: '70px',
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
    fontWeightLight: '300',
    fontWeightRegular: '400',
    fontWeightMedium: '500',
    fontWeightBold: '700',
    h1: {
      fontSize: '2.3rem',
      fontWeight: '700',
      lineHeight: '1.24',
    },
    h2: {
      fontSize: '2rem',
      fontWeight: '700',
      lineHeight: '1.31',
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: '700',
      lineHeight: '1.25',
    },
    h4: {
      fontSize: '1.375rem',
      fontWeight: '700',
      lineHeight: '1.25',
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: '700',
      lineHeight: '1.25',
    },
    h6: {
      fontSize: '1.125rem',
      fontWeight: '700',
      lineHeight: '1.25',
    },
    caption: {
      fontSize: '0.875rem',
    },
    overline: {
      fontSize: '0.75rem',
      fontWeight: '700',
    },
    body1: {
      fontSize: '1.125rem',
    },
    body2: {
      fontSize: '0.9375rem',
    },
    body3: {
      fontSize: '0.75rem',
    },
    navigationLink: {
      fontSize: '1rem',
      fontFamily: 'Poppins, Helvetica, Arial',
      fontWeight: '500',
    },
  },
  palette: {
    common: {
      black: colors.black,
      white: colors.white,
      blue: colors.blue,
      lightBlue: colors.lightBlue,
      white50: colors.white50,
    },
    primary: {
      main: colors.darkBlue,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.yellow,
      light: colors.lightYellow,
    },
    category: {
      main: 'rgba(0,0,0,0.1)',
    },
    text: {
      primary: colors.darkGray,
      secondary: colors.white50,
    },
    background: {
      default: colors.white,
      breadcrumbs: colors.lightGray,
    },
    divider: colors.dividerGray,
  },
  overrides: {
    MuiAppBar: {
      root: {
        boxShadow: 'none',
      },
    },
    MuiDrawer: {
      root: {
        width: '350px',
        flexShrink: 0,
      },
      paper: {
        maxWidth: '350px',
        width: '100%',
        color: colors.white,
        backgroundColor: colors.darkBlue,
      },
    },
    MuiLink: {
      root: {
        color: colors.blue,
      },
    },
    MuiDialog: {
      paper: {
        margin: '5px',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'inherit',
        borderRadius: '25px',
        padding: '10px 25px',
        fontSize: '1rem',
      },
      sizeSmall: {
        padding: '9px 28px',
        borderRadius: '21px',
        fontSize: '0.8125rem',
      },
      containedPrimary: {
        boxShadow: 'none',
        border: '2px solid transparent',
        '&:hover': {
          backgroundColor: colors.white,
          border: `2px solid ${colors.darkBlue}`,
          color: colors.darkBlue,
          '@media (hover: none)': {
            backgroundColor: colors.white,
          },
        },
      },
      outlinedPrimary: {
        border: `2px solid ${colors.darkBlue}`,
        '&:hover': {
          backgroundColor: colors.darkBlue,
          border: `2px solid transparent`,
          color: colors.white,
          '@media (hover: none)': {
            backgroundColor: colors.darkBlue,
          },
        },
      },
    },
    MuiBreadcrumbs: {
      root: {
        color: colors.darkGray,
      },
      separator: {
        fontSize: '1.5rem',
        marginLeft: '0px',
        marginRight: '16px',
        display: 'flex',
        alignItems: 'center',
        minHeight: '52px',
      },
      li: {
        marginRight: '16px',
        display: 'flex',
        alignItems: 'center',
        minHeight: '52px',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '8px 8px',
        [theme.breakpoints.up('sm')]: {
          padding: '8px 24px',
        },
      },
    },
    MuiInputBase: {
      root: {
        borderRadius: '40px',
        border: '2px solid',
        borderColor: colors.lightGray,
        overflow: 'hidden',
        backgroundColor: colors.lightGray,
        '&:hover:not($disabled)': {
          borderColor: colors.gray,
        },
        '& > svg': {
          color: colors.gray,
        },
        '&$focused': {
          boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.24)',
          borderColor: colors.green,
          '&:hover:not($disabled)': {
            borderColor: colors.green,
          },
        },
        '&$error:not($focused)': {
          borderColor: theme.palette.error.main,
          '&:hover:not($disabled)': {
            borderColor: theme.palette.error.main,
          },
        },
        '&$disabled': {
          backgroundColor: colors.gray,
        },
      },
      adornedStart: {
        paddingLeft: '0.5rem',
      },
      adornedEnd: {
        paddingRight: '0.5rem',
      },
      input: {
        padding: '0.625rem 1rem',
        '&:not(:first-child)': {
          paddingLeft: '0.5rem',
        },
        '&:not(:last-child)': {
          paddingRight: '0.5rem',
        },
        '&::placeholder': {
          fontStyle: 'italic',
        },
        '&::-webkit-search-cancel-button': {
          opacity: '0.50',
        },
      },
    },
    MuiInputLabel: {
      root: {
        textTransform: 'uppercase',
        marginLeft: '0.75rem',
      },
      shrink: {
        marginTop: '-8px',
        transform: 'translate(0, 1.5px) scale(1)',
      },
    },
    MuiFormLabel: {
      root: {
        color: colors.darkGray,
        '&$error': {
          color: colors.darkGray,
        },
        fontSize: '1rem',
        fontWeight: 'bold',
      },
      asterisk: {
        '&$error': {
          color: colors.darkGray,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        marginLeft: '0.75rem',
        '&$error': {
          color: colors.darkGray,
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: colors.gray,
        '&$checked': {
          color: colors.green,
        },
      },
    },
    MuiFormControl: {
      root: {
        marginBottom: theme.spacing(2),
      },
    },
    MuiTableCell: {
      head: {
        backgroundColor: colors.darkBlue,
        color: colors.white,
      },
    },
  },
  props: {
    MuiAppBar: {
      color: 'primary',
    },
    MuiIconButton: {
      color: 'primary',
    },
    MuiButton: {
      color: 'primary',
      variant: 'contained',
    },
    MuiLink: {
      variant: 'body1',
    },
    MuiContainer: {
      disableGutters: true,
    },
    MuiBreadcrumbs: {
      separator: '›',
    },
    MuiInput: {
      disableUnderline: true,
    },
    MuiInputLabel: {
      shrink: true,
    },
    MuiCheckbox: {
      checkedIcon: <CheckBoxOutlined />,
    },
  },
});

export default primaryTheme;
