module.exports = {
  siteId: process.env.GATSBY_SITE_ID,
  contentful: {
    accessToken: process.env.CONTENTFUL_ACCESS_TOKEN,
    spaceId: process.env.CONTENTFUL_SPACE_ID,
    host: process.env.CONTENTFUL_HOST,
    environment: process.env.CONTENTFUL_ENVIRONMENT,
  },
  cloudinary: {
    cloudName: process.env.GATSBY_CLOUDINARY_CLOUD_NAME,
  },
  elasticSearch: {
    endpoint: process.env.GATSBY_ELASTICSEARCH_ENDPOINT,
    indexName: process.env.GATSBY_ELASTICSEARCH_INDEX_NAME,
    apiKey: process.env.GATSBY_ELASTICSEARCH_APIKEY,
  },
  google: {
    gtag: process.env.GATSBY_GTAG_MANAGER_ID,
    env: process.env.GATSBY_GTAG_ENVIRONMENT,
  },
  azureAPI: {
    endpoint: process.env.GATSBY_AZURE_API_ENDPOINT,
  },
};
