module.exports = {
  localization: {
    default: 'en',
    supported: ['en-US', 'fi-FI', 'de-DE'],
  },
  siteMetadata: {
    title: 'Altia',
    author: 'Altia',
    description: 'Altia Gatsby Site',
    siteUrl: 'https://altiagroup.com',
  },
  manifest: {
    name: 'Altia',
    short_name: 'Altia',
    start_url: `/`,
    background_color: `#ffffff`,
    theme_color: `#000000`,
    display: 'standalone',
  },
};
