const { defaultsDeep } = require('lodash');

const defaultConfigs = require('./defaultConfigs');
const altiagroupConfigs = require('./anoragroupConfigs');

const variables = require('../variables');

/**
 * Returns site specific configs
 * @returns configuration object matching siteId
 */
function getConfigs() {
  let siteConfigs = null;

  switch (variables.siteId) {
    case 'anoragroup': {
      siteConfigs = altiagroupConfigs;
    }
  }
  /* TODO: defaultsDeep makes it impossible to overwrite defaultConfiguration values with siteConfiguration values
   * Noticeable for localization.supported (Default would preferably have every supported language where site might want
   * just Finnish for example)
   */
  return defaultsDeep({}, siteConfigs, defaultConfigs);
}

module.exports = getConfigs();
