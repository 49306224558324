import React from 'react';
import PropTypes from 'prop-types';

import { get } from 'lodash';
import { CloudinaryContext } from 'cloudinary-react';

import variables from '../../variables';

export function CloudinaryProvider({ children }) {
  return <CloudinaryContext cloudName={get(variables, 'cloudinary.cloudName')}>{children}</CloudinaryContext>;
}

CloudinaryProvider.propTypes = {
  children: PropTypes.node,
};

CloudinaryProvider.defaultProps = {
  children: null,
};
