/* eslint-disable import/no-extraneous-dependencies */

import React from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'react-helmet';
import { CssBaseline } from '@material-ui/core';

import { DialogProvider } from '../../src/components/context/DialogContext';
import { DrawerProvider } from '../../src/components/context/DrawerContext';
import { CloudinaryProvider } from '../../src/components/context/CloudinaryContext';
import { TranslationProvider } from '../../src/components/context/TranslationContext';
import { MessageProvider } from '../../src/components/context/MessageContext';
import { SearchProvider } from '../../src/components/context/SearchContext';

import variables from '../../src/variables';

import PrimaryThemeProvider from '../../src/themes/PrimaryThemeProvider';

import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';

function googleTag(w, d, s, l, i, e) {
  w[l] = w[l] || [];
  w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  const f = d.getElementsByTagName(s)[0];
  const j = d.createElement(s);
  const dl = l !== 'dataLayer' ? `&l=${l}` : '';
  j.async = true;
  j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}${e}`;
  f.parentNode.insertBefore(j, f);
}

function RootLayout({ children }) {
  let googleEnv = '';

  if (variables.google.env === 'preview') {
    googleEnv = '&gtm_auth=-IMKSo0BSHHkQC92dzjAJg&gtm_preview=env-24&gtm_cookies_win=x';
  }

  return (
    <>
      <Helmet>
        <meta name="facebook-domain-verification" content="q1dw9xe2h1lhfax3vmhg4q2v2nm921" />
        <script>
          {typeof window !== 'undefined'
            ? googleTag(window, document, 'script', 'dataLayer', variables.google.gtag, googleEnv)
            : null}
        </script>
        <meta httpEquiv="content-type" content="text/html; charset=UTF-8" />
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      </Helmet>
      <noscript>
        <iframe
          title="gtag"
          src={`https://www.googletagmanager.com/ns.html?id=${variables.google.gtag}${googleEnv}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
      <PrimaryThemeProvider>
        <CssBaseline />
        <TranslationProvider>
          <CloudinaryProvider>
            <DialogProvider>
              <MessageProvider>
                <SearchProvider>
                  <DrawerProvider>{children}</DrawerProvider>
                </SearchProvider>
              </MessageProvider>
            </DialogProvider>
          </CloudinaryProvider>
        </TranslationProvider>
      </PrimaryThemeProvider>
    </>
  );
}

RootLayout.propTypes = {
  children: PropTypes.node,
};

RootLayout.defaultProps = {
  children: null,
};

export default RootLayout;

/*
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          rel="preload"
          as="style"
          href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap"
          rel="stylesheet"
        />
*/
