/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import ReactQuery from './ReactQuery';

export const wrapRootElement = ({ element }) => {
  return <ReactQuery>{element}</ReactQuery>;
};

export const wrapPageElement = ({ element }) => {
  return (
    <>
      {element}
      {process.env !== 'production' && <ReactQueryDevtools />}
    </>
  );
};
