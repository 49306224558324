import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { viewPage } from '../../../utils/analyticUtils';

function PageWrapper({ element }) {
  useEffect(() => {
    viewPage();
  });

  return <>{element}</>;
}

PageWrapper.propTypes = {
  element: PropTypes.node,
  props: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

PageWrapper.defaultProps = {
  element: null,
  props: null,
};

export default PageWrapper;
