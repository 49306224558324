/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';

import { get } from 'lodash';
import { QueryClientProvider, QueryClient } from 'react-query';

import configs from '../../src/configs';

const queryClient = new QueryClient({ defaultOptions: get(configs, 'reactQuery.defaultOptions') });

export default function ReactQuery({ children }) {
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
}

ReactQuery.propTypes = {
  children: PropTypes.node,
};

ReactQuery.defaultProps = {
  children: null,
};
