/* eslint-disable max-len */

module.exports = {
  localization: {
    // What is the default language
    default: 'en-US',
    // What languages are supported, NOTE that you should have the locale available in Contentful before adding it as supported
    supported: ['en-US', 'fi-FI'],
  },
  siteMetadata: {
    // This information is mostly used in <header> and SEO purposes
    title: 'Anora', // Default title for incase content does not have assigned title
    author: 'Anora',
    description:
      'Anora is a leading wine and spirits brand house in the Nordic and Baltic countries, as well as an industry forerunner in sustainability.',
    siteUrl: 'https://anora.com',
  },
  cision: {
    // Configurations related to 3rd party provider Cision
    subscriptionUniqueIdentifier: 'c4c2ee0a29',
    languages: ['fi', 'en'],
    customCategories: ['357d1f63', '3c541400', '1eb16980'],
  },
  manifest: {
    name: 'Anora',
    short_name: 'Anora',
    start_url: `/`,
    background_color: `#ffffff`,
    theme_color: `#1b1d36`,
    icon: 'src/images/anoragroup/favicon.svg',
    display: 'standalone',
    cache_busting_mode: 'none',
  },
  offline: {
    workboxConfig: {
      globPatterns: ['**/icons*'],
    },
  },
  reactQuery: {
    defaultOptions: {
      queries: {
        staleTime: 1000 * 60 * 1,
      },
    },
  },
};
